import React, { Component } from "react";
import PropTypes from "prop-types";

export class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="container">
            <div className="row mb-20">
              <div className="col-lg-6 col-md-6">
                <p>Copyright @ {new Date().getFullYear()} {this.props.companyName}. Todos os direitos reservados.</p>
              </div>
            </div>
            <div className="row">
							<div className="col-lg-4">
								<h6>OUVIDORIA</h6>
								<p className="mb-0">
									<b>Telefone:</b> 0800 703 1989
								</p>
								<p className="mb-0">
									<b>E-mail:</b> ouvidoria@sinapp.org.br
								</p>
								<p className="mb-0">
									<b>Site:</b> 
									<a 
										href="https://sisgo.sinapp.net.br/solicitacao#step-1"
										target="_blank"
										rel="noopener noreferrer"
									>
										https://sisgo.sinapp.net.br/solicitacao#step-1
									</a>
								</p>
							</div>
							<div className="col-lg-4">
								<h6>OUVIDORIA DEFICIENTE VISUAL</h6>
								<p className="mb-0">
									<b>Telefone:</b> 0800 703 1989
								</p>
								<p className="mb-0">
									<b>E-mail:</b> ouvidoria.visual@sinapp.org.br
								</p>
							</div>
							<div className="col-lg-4">
								<h6>OUVIDORIA DEFICIENTE AUDITIVO OU DE FALA</h6>
								<p className="mb-0">
									<b>Telefone:</b> 0800 201 1838 (somente através de telefone fixo TDD)
								</p>
								<p className="mb-10">
									<b>E-mail:</b> ouvidoria.auditivo.fala@sinapp.org.br
								</p>
							</div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
Footer.PropsTypes = {
  companyName: PropTypes.string,
};
Footer.defaultProps = {
  companyName: "",
};
export default Footer;
