import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

//Package CSS & JS
import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap/dist/js/bootstrap.min.js"
import "animate.css/animate.min.css"

//Template SCSS & CSS Style
import "./assets/css/style.scss"
import "./assets/css/responsive.scss"

//Component Import
import GrupoAspecir from "./pages/GrupoAspecir"
import SiteContact from './pages/SiteContact'
import SiteNews from './pages/SiteNews'
import SiteCertificate from './pages/SiteCertificate'

class App extends Component {
    render() {
        return (
            <div className="App">
                <Switch >  
                    <Route
                        exact
                        path="/"
                        render={(props) => <GrupoAspecir {...props} page="grupo-aspecir" title="Grupo Aspecir" />}
                    />
                    <Route
                        exact
                        path="/noticias"
                        render={(props) => <SiteNews {...props} page="grupo-aspecir" title="Grupo Aspecir" />}
                    />
                    <Route
                        exact
                        path="/contato"
                        render={(props) => <SiteContact {...props} page="grupo-aspecir" title="Grupo Aspecir" />}
                    />
                    <Route
                        exact
                        path="/certificado"
                        render={(props) => <SiteCertificate {...props} page="grupo-aspecir" title="Grupo Aspecir" />}
                    />
                </Switch>
            </div>
        );
    }
}
export default App;
