import React, { Component } from "react";
import axios from "axios";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import PropTypes from "prop-types";
import consts from "../utils/constants";
import global from "../utils/global";

export class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      siteId: 0,
      iAddress: "",
      iComplement: "",
      iDistrict: "",
      iCep: "",
      iCity: "",
      iState: "",
      iEmail: "",
      iPhone: "",
      isLoading: true,
      name: "",
      email: "",
      phone: "",
      message: "",
      successMsg: "",
      loading: false,
    };
  }
  handleForm = (e) => {
    let that = this;
    that.setState({ loading: true });
    axios
      .post(consts.getContactUrl(), this.state, {
        headers: { Accept: "application/json" },
      })
      .then(function (response) {
        that.setState({
          successMsg: "Mensagem enviada!",
          loading: false,
          phone: "",
        });
        document.getElementById("contactForm").reset();
      })
      .catch(function (error) {});
  };

  handleFields = (e, mask) => {
    if (mask === "phone")
      this.setState({ [e.target.name]: global.setPhoneMask(e.target.value) });
    else this.setState({ [e.target.name]: e.target.value });

    this.setState({ validationMessage: "" });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    axios.get(consts.getSiteUrl(this.props.page)).then((resp) =>
      this.setState({
        ...this.state,
        siteId: resp.data.data.site.id,
        iAddress: resp.data.data.site.address,
        iComplement: resp.data.data.site.complement,
        iDistrict: resp.data.data.site.district,
        iCep: resp.data.data.site.cep,
        iCity: resp.data.data.site.city,
        iState: resp.data.data.site.state,
        iEmail: resp.data.data.site.email,
        iPhone: resp.data.data.site.phone_number,
        isLoading: false,
      })
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading ? (
          <div className="loader"></div>
        ) : (
          <section id="contact" className="contact-area ptb-50">
            <div className="container">
              <div className="section-title">
                <h2>
                  Entre em <b>contato</b>
                </h2>
                <p>{this.props.sectionContent}</p>
              </div>
              <div className="row">
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <div className="get-in-touch">
                    <h3 className="mb-1">Atendimento das 9h às 17h</h3>
                  </div>
                  <div className="contact-box">
                    <div className="icon">
                      <i className="icofont-location-pin" />
                    </div>
                    <h4>Endereço</h4>
                    <p>
                      {this.state.iAddress} - {this.state.iComplement}
                    </p>
                    <p>
                      {this.state.iDistrict}, {this.state.iCep},{" "}
                      {this.state.iCity}/{this.state.iState}
                    </p>
                  </div>
                  <div className="contact-box">
                    <div className="icon">
                      <i className="icofont-mail" />
                    </div>
                    <h4>E-mail</h4>
                    <p>{this.state.iEmail}</p>
                  </div>
                  <div className="contact-box">
                    <div className="icon">
                      <i className="icofont-phone" />
                    </div>
                    <h4>Telefone</h4>
                    <p>
                      {global.setPhoneMask(this.state.iPhone)}
                      <b
                        style={{
                          color: "#777",
                          paddingLeft: "25px",
                        }}
                      >
                        SAC 0800-6432378
                      </b>
                    </p>
                  </div>
                  <div className="contact-box">
                    <div className="icon">
                      <i className="icofont-briefcase" />
                    </div>
                    <h4>Quer trabalhar conosco?</h4>
                    <p>Venha fazer parte do nosso grupo!</p>
                    <p>
                      Envie seu currículo para:{" "}
                      <a href="mailto:vagas@grupoaspecir.com.br">
                        vagas@grupoaspecir.com.br
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12">
                  {this.state.loading ? (
                    <div className="loader"></div>
                  ) : (
                    <ValidationForm
                      id="contactForm"
                      onSubmit={(e, formData, inputs) => {
                        e.preventDefault();
                        this.handleForm(formData);
                      }}
                    >
                      {this.state.successMsg !== "" ? (
                        <div className="contactMsg">
                          <span>{this.state.successMsg}</span>
                        </div>
                      ) : null}
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <TextInput
                              name="name"
                              id="name"
                              required
                              successMessage=""
                              errorMessage={{
                                required: "Por favor, informe seu nome.",
                              }}
                              className="form-control"
                              placeholder="Nome"
                              autoComplete="off"
                              onChange={this.handleFields}
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <TextInput
                              name="phone"
                              id="phone"
                              type="tel"
                              required
                              successMessage=""
                              errorMessage={{
                                required: "Por favor, informe seu telefone.",
                                type: "Este não é um telefone válido!",
                                minLength:
                                  "Necessário informar 10 caracteres, no mínimo",
                              }}
                              className="form-control"
                              minLength={14}
                              maxLength={15}
                              placeholder="Telefone"
                              autoComplete="off"
                              onChange={(e) => this.handleFields(e, "phone")}
                              value={this.state.phone}
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <TextInput
                              name="email"
                              id="email"
                              type="email"
                              required
                              successMessage=""
                              errorMessage={{
                                required: "Por favor, informe seu e-mail.",
                                type: "Este não é um e-mail válido!",
                              }}
                              className="form-control"
                              placeholder="E-mail"
                              autoComplete="off"
                              onChange={this.handleFields}
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="form-group">
                            <TextInput
                              name="message"
                              id="description"
                              multiline
                              placeholder="Sua Mensagem"
                              className="form-control"
                              required
                              successMessage=""
                              errorMessage={{
                                required: "Por favor, escreva sua mensagem.",
                              }}
                              rows="5"
                              autoComplete="off"
                              onChange={this.handleFields}
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <button type="submit" className="btn btn-primary">
                            Enviar Mensagem
                          </button>
                          <div
                            id="msgSubmit"
                            className="h3 text-center hidden"
                          ></div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </ValidationForm>
                  )}
                </div>
              </div>
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}
ContactUs.PropsTypes = {
  siteUrl: PropTypes.string,
};
ContactUs.defaultProps = {
  siteUrl: "",
};
export default ContactUs;
