import React, { useEffect, useState } from "react";
import axios from "axios";
import consts from "../utils/constants";

import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";

//Components List
import NavBar from "../components/NavBar";
import Banner from "../components/Banner";
// import Subscribe from "../components/Subscribe";
import Welcome from "../components/Welcome";
import About from "../components/About";
import Feadback from "../components/Feadback";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import ScrollUpBtn from "../components/ScrollUpBtn";
import Section from "../components/Section";
import HolidayAlert from "../components/HolidayAlert/HolidayAlert";
import iconOffline from "../assets/img/icon_offline.png";

const GrupoAspecir = () => {
  const [state, setState] = useState({
    address: "",
    complement: "",
    district: "",
    cep: "",
    city: "",
    state: "",
    email: "",
    phone: "",
    banners: [],
    news: [],
    isLoading: true,
  });
  const [disponibility, setDisponibility] = useState({
    status: "false",
    text: "",
    script: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    axios.get(consts.getSitePageUrl()).then((resp) =>
      setState({
        ...state,
        address: resp.data.data.site.address,
        complement: resp.data.data.site.complement,
        district: resp.data.data.site.district,
        cep: resp.data.data.site.cep,
        city: resp.data.data.site.city,
        state: resp.data.data.site.state,
        email: resp.data.data.site.email,
        phone: resp.data.data.site.phone_number,
        banners: resp.data.data.site.banners,
        news: resp.data.data.site.posts,
        isLoading: false,
      })
    );
  }, []);

  useEffect(() => {
    if (disponibility.status === "online") {
      var scriptString = disponibility.script;
      const partes = scriptString.match(/"(.*?)"|'(.*?)'/g);

      if (partes) {
        const src = partes[0].replace(/"|'/g, "");
        const token = partes[1].replace(/"|'/g, "");
        const script = document.createElement("script");
        script.src = src;
        script.setAttribute("token", token);
        script.async = true;
        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      }
    }
  }, [disponibility]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    axios
      .post(consts.getApiChatBotUrl(), { site: 2 })
      .then((resp) => {
        setDisponibility({
          status: "online",
          script: resp.data,
        });
      })
      .catch((error) => {
        setDisponibility({
          status: "offline",
          text: error.response.data.unavailability_message,
          script: "",
          image: error.response.data.image,
        });
      });
  }, []);

  return (
    <div className="grupo-aspecir-page">
      {state.isLoading ? (
        <div className="loader"></div>
      ) : (
        <React.Fragment>
          <NavBar
            site={consts.APP_NAME_GRUPO_ASPECIR}
            logo={consts.LOGO_GRUPO_ASPECIR}
            logoNegativo={consts.LOGO_NEGATIVO_GRUPO_ASPECIR}
          />

          <Banner homeslidesData={state.banners} />

          <Section />

          {/* <Subscribe
                            label="Receba nossas novidades"
                            placeholder="nome@email.com"
                        /> */}

          <Welcome
            welcomesData={[
              {
                icon: require("../assets/img/plan-previdencia.png"),
                key: "social-security",
                title: "Previdência",
                url: consts.ASPECIR_PREVIDENCIA_URL,
                content:
                  "A Previdência Privada é um investimento a longo prazo que garante uma renda financeira para o futuro. Através de aportes mensais, seu dinheiro rende mais e você resgata o valor no final do plano, utilizando a quantia acumulada para aproveitar a vida sem preocupações.",
              },
              {
                icon: require("../assets/img/plan-seguros.png"),
                key: "insurance",
                title: "Seguros",
                url: consts.UNIAO_SEGURADORA_URL,
                content:
                  "A melhor forma de cuidar de quem você ama: um seguro protege o segurado e/ou os dependentes contra imprevistos. Caso algo aconteça, você tem a certeza de que o mais importante está sendo bem cuidado.",
              },
              {
                icon: require("../assets/img/plan-emprestimos.png"),
                key: "loan",
                title: "Empréstimos",
                url: consts.ASPECIR_EMPRESTIMOS_URL,
                content:
                  "Precisando de ajuda para realizar um sonho ou sair do aperto financeiro? Os Empréstimos do Grupo Aspecir oferecem baixas taxas de juros, prazo prolongado e as melhores condições do mercado.",
              },
            ]}
          />

          <About
            item2topTitle="Sobre o"
            item2topTitlespan="Grupo"
            item2Title="Conheça um pouco sobre o"
            item2Titlespan="Grupo Aspecir"
            sectionImage={require("../assets/img/predio-grupo-aspecir.jpg")}
            item2ContentTop="Composto por empresas como Aspecir Previdência, União Seguradora, Futuro Previdência e Aspecir Empréstimos, o Grupo Aspecir, comandado pelo presidente Milton Machado e o diretor Júlio Machado, tem como prioridade a previdência, o seguro e a assistência financeira para aposentados e pensionistas do INSS, IPÊ, Exército, Marinha, Aeronáutica, Tesouro RS, SIAPE e empresas."
            item2ContentMiddle="O objetivo é atender com a melhor cobertura de serviços o mercado na região Sul, com planejamento para todo o território nacional. Hoje, as empresas crescem trabalhando como um Grupo que tem mais de 100 anos no mercado, sempre atuando com simplicidade, integridade e orçamento voltado a atender as necessidades de cada empresa e de seus associados."
            item2ContentBottom="A parceria entre as empresas tem por meta a excelência em seus produtos, investimentos em inovação, alicerce sólido em fundamentos e valores éticos, para resistir às especulações, crises e seus impactos negativos. Cada empresa preza por seus produtos e conta com a parceria do Grupo para manter a sustentabilidade dos negócios e oferecer o melhor ao mercado."
          />

          <Feadback
            sectionTitleSpan="Notícias"
            buttonText="Mais Notícias"
            newsPageUrl="/noticias"
            feadbacksData={state.news}
          />
          <ContactUs page={"grupo-aspecir"} />
          {disponibility.status === "offline" && disponibility.text != null && (
            <div className="schedule_config">
              <Button onClick={handlePopoverOpen}>
                <img
                  style={{ width: "50px", height: "50px" }}
                  src={disponibility.image ? disponibility.image : iconOffline}
                  alt=""
                />
              </Button>
              <Popover
                anchorEl={anchorEl}
                open={open}
                id={open ? "simple-popover" : undefined}
                style={{ width: "40%" }}
                onClose={handlePopoverClose}
                transformOrigin={{
                  horizontal: "left",
                  vertical: "bottom",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "center",
                }}
              >
                <div style={{ padding: "10px", whiteSpace: "pre-wrap" }}>{disponibility.text}</div>
              </Popover>
            </div>
          )}
          <Footer companyName={consts.APP_NAME_GRUPO_ASPECIR} />
          <ScrollUpBtn />
          <HolidayAlert />
        </React.Fragment>
      )}
    </div>
  );
};

export default GrupoAspecir;
