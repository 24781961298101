import React, { Component } from "react";
import consts from "../utils/constants";

import NavBar from "../components/NavBarInterna";
import Certificate from "../components/Certificate";
import Footer from "../components/Footer";

class SiteCertificate extends Component {
  render() {
    const page = this.props.page;
    const [site, logo, logoNegativo] = consts.getInternalPageData(page);
    return (
      <React.Fragment>
        <div className={`internal-page contact-page ${page}-page`}>
          <div className="main-container">
            <NavBar
              site={site}
              logo={logo}
              logoNegativo={logoNegativo}
              page={page}
            />
            <Certificate page={page} />
            <Footer companyName={site} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default SiteCertificate;