import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import Icofont from "react-icofont";
import SideNav from "../components/SideNav";
import consts from "../utils/constants";

class NavBar extends Component {
  closeNavbar() {
    if (window.matchMedia("screen and (max-width: 991px)").matches) {
      document.getElementById("collaspe-btn").click();
    }
  }
  render() {
    return (
      <React.Fragment>
        <Navbar
          sticky="top"
          id="navbar"
          bg="light"
          expand="xl"
          className="navbar navbar-expand-xl"
          collapseOnSelect={true}
        >
          <Container>
            <Navbar.Brand>
              <Link to="/">
                <img
                  src={this.props.logoNegativo}
                  alt={`Logo ${this.props.site}`}
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" id="collaspe-btn" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Item>
                  <Link to="/" className="nav-link" onClick={this.closeNavbar}>
                    Home
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link
                    to="/noticias"
                    className="nav-link"
                    onClick={this.closeNavbar}
                  >
                    Notícias
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link
                    to="/contato"
                    className="nav-link"
                    onClick={this.closeNavbar}
                  >
                    Contato
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <a href={consts.PORTAL_CORRETOR_URL} className="nav-link">
                    <Icofont icon="lock" />
                    Parceiro
                  </a>
                </Nav.Item>
                <Nav.Item>
                  <a href={consts.PORTAL_CLIENTE_URL} className="nav-link">
                    <Icofont icon="lock" />
                    Portal do Cliente
                  </a>
                </Nav.Item>
                <Nav.Item>
                  <SideNav
                    page={this.props.page}
                    logo={this.props.logo}
                    site={this.props.logoNegativo}
                    locationbolt="Praça Otávio Rocha, 65 - 1º andar"
                    locationnormal="Centro Histórico, 90020-140, Porto Alegre/RS"
                    phonebolt="+55 (51) 3228.1999"
                    mailbolot="atendimento@aspecir.com.br"
                    hasSocialLinks={
                      this.props.page === consts.UNIAO_SEGURADORA_URL && true
                    }
                    facebooklink={
                      this.props.page === consts.UNIAO_SEGURADORA_URL &&
                      "http://www.facebook.com/grupoaspecir/"
                    }
                  />
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default NavBar;
