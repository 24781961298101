import React from "react"
import ReactHtmlParser from 'react-html-parser'

export default props => (
  <React.Fragment>
    <div className="news-aside-list">
      {props.asideData.map((news, index) => (
        <div key={index} onClick={() => props.handleAsideClick(news)} className="new-aside-item">
          <small>{news.created_at}</small>
          <h5>{news.title}</h5>
          <p>{ReactHtmlParser(news.preview)}{news.preview && '...'}</p>
          {props.asideData.length - 1 !== index && <hr />}
        </div>
      ))}
    </div>
  </React.Fragment>
)